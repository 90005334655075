<template>
  <div
    class="row"
  >
    <div
      :class="isPlayed === null && !isRecording ? 'd-none' : null"
      class="video-record d-md-block col-md-6"
      style="height: calc(100vh - 20px)"
    >
      <div class="h-100">
        <ClientOnly>
          <Teleport
            to="#ItemInfoModal"
            :disabled="isDesktop"
          >
            <div
              ref="videoViewComp"
              class="video-record-wrap h-100 bg-white rounded-20 position-relative"
              :style="{ height: wrapHeight === undefined ? undefined : wrapHeight + 'px !important' }"
            >
              <div
                class="position-absolute top-0 left-0 p-20 w-100 d-flex d-md-none justify-content-between align-items-center heading-text-1"
                style="z-index: 1;"
              >
                <AppButton
                  class="text-white"
                  :variant="BtnVariant.Link"
                  is-icon
                  @click="recordModalClose"
                >
                  <svg class="flip-horizontal h-100 w-100">
                    <use xlink:href="/public/icons/main.svg#east" />
                  </svg>
                </AppButton>
                <span
                  v-show="((!isRecording && !answered) || isNew || isDubbing) && !isPhoto"
                  class="text-white"
                >
                  Нажмите на запись
                </span>
                <span
                  v-show="isPhoto && photoSrc === null"
                  class="text-white"
                >
                  Сделайте фото
                </span>
                <span
                  v-show="isRecording && !isNew && !isDubbing"
                  class="text-white"
                >
                  Осталось <b>{{ isStreamOpen ? recordCountdown : questions[activeQuestion].time }} сек.</b>
                </span>
              </div>
              <VideoView
                v-for="(_, index) in questions"
                v-show="index === activeQuestion && !isPhoto"
                class="video-record-wrap__view overflow-hidden"
                :audio-stream="audioStream"
                :autoplay="isPlayed === index"
                :is-assembled="isAssembled || isRecording || isStreamOpen || isPlayed !== null || recorded"
                :is-reflection="(isRecording && activeQuestion === index && isFaceMode) || (questions[index].recordBlob !== null && questions[index].isFlip)"
                :is-play="!allView && isPlayed === index"
                :is-next-play="allView && isPlayed === index"
                :src="getUrl(index)"
                :stream="isRecording && activeQuestion === index ? streamNew : undefined"
                :video-height="videoHeight"
                :video-scale="isFaceMode"
                :video-width="videoWidth"
                :wrap-height="wrapHeight"
                :wrap-width="wrapWidth"
                :is-blob="questions[index].recordBlob !== null"
                @pause="() => {if (isPlayed !== null || isRecording) stopRecord()}"
                @play="() => {if (answered) isPlayed = activeQuestion}"
                @stop="onStopVideo"
                @is-finish="nextVideo"
              />
              <VideoView
                v-show="isPhoto"
                class="video-record-wrap__view overflow-hidden"
                :autoplay="photoSrc === null"
                is-reflection
                :is-play="photoSrc === null"
                :stream="streamNew"
                :video-height="videoHeight"
                :video-scale="videoScale"
                :video-width="videoWidth"
                :wrap-height="wrapHeight"
                :wrap-width="wrapWidth"
                :photo-src-old="photoSrc"
                :is-set-photo="isSetPhoto"
                is-photo
                @get-photo="getPhoto"
              />
              <button
                v-show="answered && !isRecording && isPlayed === null && (isAssembled || recorded) && !isPhoto"
                class="position-absolute top-50 start-50 translate-middle btn bg-dark bg-opacity-25 rounded-circle"
                style="width: 120px; height: 120px;"
                @click="isPlayed = activeQuestion"
              >
                <svg class="h-100 w-100 text-white">
                  <use xlink:href="/public/icons/main.svg#play-arrow" />
                </svg>
              </button>
              <button
                v-if="!isDesktop && isCameraFlip"
                class="video-record-flip position-absolute d-flex d-md-none rounded-circle border border-2 border-white bg-transparent translate-middle-x"
                :class="{
                  'd-none': (answered && !isDubbing) || (isRecording && !isNew && !isDubbing) || isPhoto,
                }"
                @click="flipCamera"
              >
                <svg class="h-100 w-100 text-white">
                  <use xlink:href="/public/icons/auth-sprite.svg#flip-camera" />
                </svg>
              </button>
              <button
                class="video-record-control position-absolute d-flex d-md-none start-50 rounded-circle bg-transparent justify-content-center align-items-center translate-middle-x"
                :class="{
                  'd-none': (answered && !isDubbing) || isPhoto,
                  'video-record-control_stop': isRecording && !isNew && !isDubbing,
                }"
                :title="(isRecording || isPlayed !== null) && !isNew ? 'Стоп' : 'Старт'"
                :disabled="isLockedStop"
                @click="(isRecording || isPlayed !== null) && !isNew && !isDubbing ? stopRecord() : startView()"
              >
                <span class="video-record-control__indicator p-0 flex-shrink-0" />
              </button>
              <button
                class="video-record-control position-absolute d-flex d-md-none start-50 rounded-circle align-items-center justify-content-center bg-transparent translate-middle-x"
                :class="{
                  'd-none': !isPhoto || photoSrc !== null
                }"
                @click="setPhoto"
              >
                <svg class="photo-icon">
                  <use xlink:href="/public/icons/auth-sprite.svg#photo-camera" />
                </svg>
              </button>
              <div
                class="position-absolute bottom-0 left-0 p-20 w-100 d-flex d-md-none justify-content-center"
                :class="{
                  'd-none': (!isPhoto && !answered) || (isPhoto && photoSrc === null) || isDubbing,
                }"
              >
                <AppButton
                  v-show="!isCameraDenied || !isCameraDeniedInterview"
                  :variant="BtnVariant.White"
                  @click="isPhoto ? setPhoto() : startDubbing()"
                >
                  {{ isPhoto ? 'Переcнять' : 'Перезаписать' }}
                </AppButton>
                <AppButton
                  class="ms-15"
                  :variant="BtnVariant.Primary"
                  @click="itemInfoModalShow = false"
                >
                  Готово
                </AppButton>
              </div>
            </div>
          </Teleport>
        </ClientOnly>
      </div>
    </div>

    <div class="col-12 col-md-6">
      <div class="h-100 bg-white rounded-20 px-10 py-20 px-sm-20 py-sm-34 position-relative">
        <div
          class="h-100"
          :class="{ 'd-none': activeSlot, 'd-flex align-items-center': isPhoto }"
        >
          <div
            v-if="isPhoto"
            class="w-100 d-flex flex-column align-items-center"
          >
            <div class="d-flex justify-content-center mb-20 align-items-center">
              <div class="h3 fw-bold lh-1">
                Фото
              </div>
              <AppButton
                v-if="false"
                class="ms-15 flex-shrink-0"
                :variant="BtnVariant.Tertiary"
                is-icon
              >
                <svg class="w-100 h-100">
                  <use xlink:href="/public/icons/main.svg#info" />
                </svg>
              </AppButton>
            </div>
            <p
              v-show="photoSrc === null || isDesktop"
              class="text-center mb-34"
            >
              Сфотографируй себя на расстоянии вытянутой руки.
            </p>
            <div
              class="d-flex gap-10"
              :class="{'d-none': !isDesktop}"
            >
              <AppButton
                v-show="!isCameraDenied"
                class="fw-bold"
                @click="setPhoto"
              >
                {{ photoSrc !== null ? 'Переснять' : 'Сфотографировать' }}
              </AppButton>
              <div
                :class="{ 'd-none': photoSrc === null }"
                class="d-flex"
              >
                <slot name="nextStep" />
              </div>
            </div>
            <div v-show="photoSrc !== null && !isDesktop">
              <div class="mb-10 position-relative">
                <img
                  class="photo rounded-20"
                  style="object-fit: cover;"
                  :src="photoSrc"
                  alt="Фото"
                />
                <AppButton
                  v-show="!isCameraDenied"
                  class="edit_photo position-absolute"
                  :variant="BtnVariant.White"
                  @click="itemInfoModalShow = true"
                >
                  Редактировать
                </AppButton>
              </div>
              <slot name="nextStep" />
            </div>
            <AppButton
              v-show="!isDesktop && photoSrc === null && !isCameraDenied"
              class="fw-bold"
              @click="itemInfoModalShow = true"
            >
              Перейти к фотографированию
            </AppButton>
          </div>
          <div
            v-if="questions.length > 0 && !isPhoto"
            class="h-100 d-flex flex-column align-content-center align-items-center justify-content-between"
          >
            <div class="w-100 mb-34">
              <p class="text-center mb-15">
                К каждому вопросу можно вернуться и перезаписать.
                <NuxtLink
                  class="text-primary"
                  role="button"
                  @click="openVideoRules"
                >
                  Правила
                </NuxtLink>.
              </p>
              <div class="mb-15 fw-bold h4">
                Вопросы
              </div>
              <StepsNumber
                :steps="recordIsDoneList"
                :active-step="activeQuestion"
                @change-step="changeQuestion"
              />
            </div>
            <div class="mb-34">
              <div class="d-flex justify-content-center">
                <div class="d-flex flex-column align-items-center">
                  <span class="lh-1">Вопрос №{{ activeQuestion + 1 }}</span>
                  <div class="h3 fw-bold lh-1">
                    {{ questions[activeQuestion].question }}
                  </div>
                </div>
                <AppButton
                  v-show="questions[activeQuestion].hint !== null"
                  class="ms-15 flex-shrink-0"
                  :variant="BtnVariant.Tertiary"
                  is-icon
                  @click="questionHintModalShow = true"
                >
                  <svg class="w-100 h-100">
                    <use xlink:href="/public/icons/main.svg#info" />
                  </svg>
                </AppButton>
              </div>
              <p class="text-center">
                На ответ будет дано {{ questions[activeQuestion].time }} сек. Рекомендуем заранее подготовиться к вопросу
              </p>
            </div>

            <div style="display: none !important;">
              <span
                v-show="recordCountdownInterval !== null"
                class="h1 fw-bold text-primary"
              >
                {{ isStreamOpen ? recordCountdown : questions[activeQuestion].time }} сек.
              </span>
              <div v-show="!isRecording && isPlayed === null && (questions[activeQuestion].recordBlob !== null || answered)">
                <AppButton
                  class="shadow-1"
                  :disabled="!isAssembled && !recorded"
                  title="Просмотр записи"
                  is-icon
                  :variant="BtnVariant.White"
                  @click="playRecord(activeQuestion)"
                >
                  <svg class="h-100 w-100">
                    <use xlink:href="/public/icons/main.svg#play-arrow" />
                  </svg>
                </AppButton>

                <AppButton
                  class="shadow-1 ms-15"
                  :title="questions[activeQuestion].answer === null ? 'Перезаписать' : 'Записать'"
                  is-icon
                  :variant="BtnVariant.White"
                  @click="startDubbing"
                >
                  <svg class="h-100 w-100">
                    <use :xlink:href="'/public/icons/recordSprite.svg#' + ( questions[activeQuestion].answer === null ? 'replay' : 'video-camera-front')" />
                  </svg>
                </AppButton>
              </div>
            </div>

            <div
              v-show="!isRecording && answered"
              class="mx-auto mb-34 shadow-1 py-10 px-20 rounded-20"
            >
              <div style="color: #27AE60;">
                ОТВЕТ ЗАПИСАН
              </div>
              <AppButton
                v-show="!(isCameraDenied && isDesktop) || !(isCameraDeniedInterview && isDesktop)"
                class="shadow-1 mt-15"
                :variant="BtnVariant.White"
                @click="isDesktop ? startDubbing() : itemInfoModalShow = true"
              >
                {{ isDesktop ? 'Перезаписать' : 'Просмотреть' }}
              </AppButton>
            </div>
            <div
              v-show="isRecording && !isNew && !isDubbing"
              class="h4"
            >
              Осталось
              <span class="text-primary">
                {{ recordCountdown }} сек
              </span>
            </div>
            <div
              class="d-flex gap-10"
            >
              <AppButton
                v-show="isDesktop && (isDubbing || (!answered && (!isRecording || isNew))) && (!isCameraDenied || !isCameraDeniedInterview)"
                class="fw-bold"
                :disabled="questions === null || isLockedStop"
                @click="(isRecording || isPlayed !== null) && !isNew && !isDubbing ? stopRecord() : startView()"
              >
                Начать запись
              </AppButton>
              <AppButton
                v-show="isDesktop && isRecording && !isNew && !isDubbing"
                class="fw-bold"
                :disabled="questions === null || isLockedStop"
                :variant="BtnVariant.Tertiary"
                @click="(isRecording || isPlayed !== null) && !isNew ? stopRecord() : startView()"
              >
                Остановить запись
              </AppButton>
              <AppButton
                v-show="!isDesktop && !answered && isNew && (!isCameraDenied || !isCameraDeniedInterview)"
                class="fw-bold"
                :disabled="questions === null || isLockedStop"
                @click="itemInfoModalShow = true; isRecording = true; isPlayed = activeQuestion"
              >
                Перейти к записи ответа
              </AppButton>
              <AppButton
                :class="{ 'd-none': !(recorded || answered) || isLastQuestion}"
                class="fw-bold mx-auto"
                @click="nextQuestion"
              >
                Следующий вопрос
              </AppButton>
              <AppButton
                v-if="activeVideoHub && !isFirstVacancies"
                class="shadow-1"
                :variant="BtnVariant.White"
                is-icon
                @click="openVideoHub"
              >
                <svg class="h-100 w-100">
                  <use xlink:href="/public/icons/recordSprite.svg#folder-open" />
                </svg>
              </AppButton>
              <AppButton
                v-show="!isRequired"
                class="shadow-1"
                :variant="BtnVariant.White"
                @click="skip"
              >
                Пропустить
              </AppButton>
              <div
                :class="{ 'd-none': !(recorded || answered) || !isLastQuestion }"
                class="d-flex"
              >
                <slot name="nextStep" />
              </div>
            </div>
          </div>
          <AppLoader
            :is-hide="questions !== undefined"
            class="w-100"
          />
        </div>
        <div
          :class="{ 'd-none': !activeSlot}"
          class="h-100"
        >
          <slot :record="{ isPlayed, playRecord }" />
        </div>
      </div>
    </div>

    <VideoHubModal />
    <VueFinalModal
      v-model="questionHintModalShow"
      content-class="modal-dialog modal-dialog-centered modal-fullscreen-md-down"
      display-directive="show"
    >
      <div class="modal-content">
        <div class="modal-header">
          <p class="heading-section-2 fw-normal modal-title fs-5">
            {{ questions[activeQuestion].question }}
          </p>
          <button
            class="btn-close"
            type="button"
            aria-label="Закрыть"
            title="Закрыть"
            @click="questionHintModalShow = false"
          />
        </div>
        <div
          class="modal-body"
          style="white-space: pre-line"
        >
          <b>Подсказка:</b><br>
          {{ questions[activeQuestion].hint }}
        </div>
      </div>
    </VueFinalModal>

    <VueFinalModal
      v-model="itemInfoModalShow"
      display-directive="show"
    >
      <div id="ItemInfoModal" />
    </VueFinalModal>
  </div>
</template>

<script lang="ts" setup>
import { useModal, VueFinalModal } from "vue-final-modal";
import VideoHubModal from "~/components/VideoHub/VideoHubModal.vue";
import VideoRecordingRulesModal from "~/components/modal/VideoRecordingRulesModal.vue";
import type { CompanyVideo } from "~/entities/CompanyVideo";
import type { QuestionRecord } from "~/entities/QuestionRecord";
import { BtnVariant } from "~/composables/enums/BtnVariant";
import AppLoader from "~/components/AppLoader.vue";
import { useCheckMobile } from "~/composables/CheckMobile";
import { useVideoSettings } from "~/composables/videoSettings";
import { useClient } from "~/stores/client";
import type { PropType } from "vue";

const { $toast } = useNuxtApp();
const route = useRoute();
const client = useClient();
const useVideoHubModal = useModal({
  component: VideoHubModal,
  attrs: {
    onConfirm() {
      useVideoHubModal.close();
    },
    onChangeVideo: changeCompanyVideo
  }
});
const useVideoRecordingRulesModal = useModal({
  component: VideoRecordingRulesModal,
  attrs: {
    onConfirm() {
      useVideoRecordingRulesModal.close();
    },
  }
});

//-----PROPS-----\\
const props = defineProps({
  questions: { type: Array as PropType<QuestionRecord[]>, required: true },
  isActive: { type: Boolean, default: true },
  activeSlot: { type: Boolean, default: false },
  isUpdate: { type: Boolean, default: false },
  activeVideoHub: { type: Boolean, default: false },
  isShow: { type: Boolean },
  stream: { type: Object as PropType<MediaStream>, default: undefined },
  isInterview: { type: Boolean, default: false },
  isCameraDenied: { type: Boolean, default: true },
  isPhoto: { type: Boolean, default: false },
  photoSrcOld: { type: String, default: null },
  isCameraFlip: { type: Boolean, default: () => false },
});

const emit = defineEmits([
  'webmSupported',
  'startRecord',
  'changeCompanyVideo',
  'setRecordBlob',
  'lastSkip',
  'update:questionsProp',
  'getPhoto',
  'changeFaceMode',
  'changeQuestion',
]);

//-----VARIABLES-----\\
let recordCountdownInterval: Timer|null = null;
//===Запись на стороне клиента===\\
let isDesktop: boolean = false;
let mediaRecord: MediaRecorder|null = null;
let blobsRecord: BlobPart[] = [];
let flipToast: number|null = null;
let isWebmSupported = true;
// const webmType = 'video/webm;codecs="vp8,opus"';
const webmType = 'video/webm;codecs="vp8,opus"';
const mp4Type = 'video/mp4;codecs="avc1.42E01E,mp4a.40.2"';
let isFaceMode = true;

//-----STATE-----\\
const activeQuestion = ref<number>(0);
const questionHintModalShow = ref<boolean>(false);
const streamNew = ref<MediaStream>();
const audioStream = ref<MediaStream|null>(null);
const isPlayed = ref<number|null>(null);
const isRecording = ref<boolean>(false);
const recordCountdown = ref<number|null>(null);
const isLockedStop = ref<boolean>(false);
const isNew = ref<boolean>(true);
const photoSrc = ref<string|null>(props.photoSrcOld??null);
const isSetPhoto = ref<boolean>(props.photoSrcOld !== null);
const isFirstVacancies = ref<boolean>(false);
//===Запись на стороне клиента===\\
const videoViewComp = ref();
const videoHeight = ref();
const videoWidth = ref();
const videoScale = ref();
const wrapHeight = ref();
const wrapWidth = ref();
const itemInfoModalShow = ref(false);
const allView = ref<boolean>(false);
const isCameraDeniedInterview = ref<boolean>(true);

//-----COMPUTED-----\\
const isNotEmptyQuestions = computed(():boolean => props.questions.length > 0);
const isStreamOpen = computed(():boolean => streamNew.value !== undefined);
const isLastQuestion = computed(():boolean => activeQuestion.value === props.questions.length - 1);
const isAssembled = computed(() => {
  if (props.questions[activeQuestion.value].answer === null) {
    return true;
  }
  return props.questions[activeQuestion.value].answer.isAssembled;
  // return props.questions[activeQuestion.value].answer !== undefined && props.questions[activeQuestion.value].answer.src !== null;
});
const isRequired = computed(() => {
  return props.questions[activeQuestion.value].isRequired === true;
});
//===Запись на стороне клиента===\\
const recorded = computed((): boolean => !isRecording.value && isNotEmptyQuestions.value && props.questions[activeQuestion.value].recordBlob !== null);
const answered = computed(() => {
  return props.questions[activeQuestion.value].recordBlob !== null || props.questions[activeQuestion.value].answer !== null;
});
const recordIsDoneList = computed<boolean[]>(() => {
  return props.questions.map(record => record.recordBlob !== null || record.answer !== null);
});
const isDubbing = computed<boolean>(() => {
  return answered.value && isRecording.value;
});

const unwatchShowStatus = watch(() => props.isShow, async (newStatus: boolean) => {
  if (newStatus === true && wrapHeight.value === undefined) {
    await nextTick();
    getWrapSize();
  }
});
watch(() => props.stream, (val) => {
  streamNew.value = val;
  if (props.questions[activeQuestion.value].recordBlob === null && !props.isUpdate && props.isActive && !props.isPhoto) {
    isRecording.value = true;
    isPlayed.value = activeQuestion.value;
  } else {
    isRecording.value = false;
    isPlayed.value = null;
  }
});
watch(() => props.activeSlot, (val) => {
  if (val) {
    isRecording.value = false;
    isPlayed.value = null;
  }
});

//-----ASYNC-----\\
await (async () => {
  if (props.activeVideoHub) {
    useVideoHubModal.options.attrs.id = route.params.id;
  }

  if (props.isInterview) {
    try {
      await initStream();

      isCameraDeniedInterview.value = false;
      isRecording.value = true;
      isPlayed.value = activeQuestion.value;
    } catch (err) {
      $toast.error('Не удалось получить доступ к видеокамере и/или микрофону');
      return;
    }
  }
  if (client.activeCompany !== null) {
    const { data } = await mainFetch(`companies/${client.activeCompany.id}/videos`);
    isFirstVacancies.value = data.value.companyVideos.count === 0;
  }
})();

//-----METHODS-----\\
function getPhoto(params: any) {
  if (params.photoBlob !== null) {
    photoSrc.value = params.photoSrc;
  } else {
    photoSrc.value = null;
  }
  emit('getPhoto', {photoSrc: photoSrc.value, photoBlob: params.photoBlob});
}
function setPhoto() {
  photoSrc.value = null;
  isSetPhoto.value = !isSetPhoto.value;
}
function nextQuestion() {
  if (activeQuestion.value === props.questions.lastIndexOf()) {
    return;
  }

  changeQuestion(activeQuestion.value + 1);
}
function changeQuestion(question:number) {
  stopRecord();

  emit('changeQuestion', activeQuestion.value, question);

  activeQuestion.value = question;

  if (props.questions[question].recordBlob === null && props.questions[question].answer === null && props.isActive) {
    isRecording.value = true;
    isPlayed.value = question;
    isNew.value = true;
  } else {
    isNew.value = false;
    isRecording.value = false;
    isPlayed.value = null;
  }
}

function openVideoRules() {
  useVideoRecordingRulesModal.open();
  useVideoRecordingRulesModal.options.attrs.isVacancy = client.activeCompany !== null;
}

//===Запись на стороне клиента===\\
async function initStream() {
  const settings = useVideoSettings(isDesktop);
  settings.video.facingMode = isFaceMode ? 'user' : 'environment';

  streamNew.value = await navigator.mediaDevices.getUserMedia(settings);
}
async function startView() {
  if (flipToast !== null) {
    return;
  }
  isNew.value = false;
  emit("setRecordBlob", activeQuestion.value, null);

  if (streamNew.value !== undefined) {
    isWebmSupported = MediaRecorder.isTypeSupported(webmType);
    emit('webmSupported', isWebmSupported);
  }
  isRecording.value = true;
  startRecord();
}
function startDubbing() {
  isPlayed.value = activeQuestion.value;
  isRecording.value = true;
  // isNew.value = true;
  // startView();
}
function startRecord() {
  isPlayed.value = activeQuestion.value;
  isRecording.value = true;
  recordCountdown.value = props.questions[activeQuestion.value].time;// Устанавливаем время на ответ

  mediaRecord = new MediaRecorder(streamNew.value, {
    mimeType: isWebmSupported ? webmType : mp4Type,
    audioBitsPerSecond: 128000,
    videoBitsPerSecond: 2500000,
  });
  mediaRecord.addEventListener('dataavailable', e => {
    blobsRecord.push(e.data);
  });

  mediaRecord.addEventListener('stop', () => {
    const blob = new Blob(blobsRecord, {
      type: isWebmSupported ? webmType : mp4Type
    });
    blobsRecord = [];
    emit("setRecordBlob", activeQuestion.value, blob);
  });

  mediaRecord.start(1000);

  recordCountdownInterval = setInterval(() => {
    recordCountdown.value!--;
    if (recordCountdown.value! <= 0) {
      stopRecord();// Останавливаем запись
    }
  }, 1000);
}
function stopRecord() {
  if (allView.value && isPlayed.value !== null) {
    return;
  }
  if (mediaRecord !== null) {
    mediaRecord.stop();
  }

  isLockedStop.value = false;
  isPlayed.value = null;
  isRecording.value = false;

  if (recordCountdownInterval !== null) {
    clearInterval(recordCountdownInterval);
    recordCountdownInterval = null;
  }
}
async function flipCamera() {
  if (streamNew.value === null) {
    return;
  }

  emit('changeFaceMode');

  streamNew.value?.getTracks().forEach(track => {
    track.stop();
  });

  isFaceMode = !isFaceMode;

  isRecording.value = true;

  await initStream();

  isPlayed.value = activeQuestion.value;
  isRecording.value = true;
}
//===/Запись на стороне клиента===\\
function recordModalClose() {
  stopRecord();
  itemInfoModalShow.value = false;
}
function onStopVideo() {
  if (allView.value) {
    return;
  }

  isPlayed.value = null;
}
function playRecord(index: number, isAllView: boolean = false) {
  if (props.questions[index].recordBlob === null && props.questions[index].answer === null) {
    allView.value = false;
    startRecord();

    return;
  }
  allView.value = isAllView;

  activeQuestion.value = index;
  isPlayed.value = index;
  isRecording.value = false;
  openModal();
}
function nextVideo() {
  if (allView.value && isPlayed.value !== null && isPlayed.value < props.questions?.length - 1) {
    playRecord(isPlayed.value + 1, true);
  }
}
function openVideoHub() {
  useVideoHubModal.options.attrs.questionId = props.questions[activeQuestion.value].id;
  if (props.questions[activeQuestion.value].answer !== null) {
    useVideoHubModal.options.attrs.activeItem = props.questions[activeQuestion.value].answer.id;
  }
  useVideoHubModal.open();
}
function changeCompanyVideo(item: CompanyVideo) {
  isRecording.value = false;
  isPlayed.value = null;
  emit('changeCompanyVideo', activeQuestion.value, item);
}
function skip() {
  if (activeQuestion.value === props.questions.length - 1) {
    emit('lastSkip');
  }
}

function isPortrait() {
  return window.innerWidth <= window.innerHeight;
}
function windowResize() {
  if (itemInfoModalShow.value === true) {
    wrapHeight.value = window.innerHeight;
  }

  if (!props.isActive || isDesktop || isPortrait()) {
    if (flipToast !== null) {
      $toast.dismiss(flipToast);
      flipToast = null;
    }
    return;
  }

  if (flipToast === null) {
    flipToast = $toast.error('Переверните устройство', {
      timeout: false,
      closeOnClick: false,
      draggable: false,
      closeButton: false,
    });
  }

  stopRecord();
}

function getUrl(index: number): string|undefined {
  if (props.questions[index].recordBlob !== null) {
    return  URL.createObjectURL(props.questions[index].recordBlob!);
  } else if (!props.isPhoto && props.questions[index].answer !== null && props.questions[index].answer!.src !== null) {
    return props.questions[index].answer!.src!;
  }
  
  return undefined;
}
function getWrapSize() {
  unwatchShowStatus();
  if (isDesktop) {
    wrapHeight.value = videoViewComp.value?.offsetHeight;
    wrapWidth.value = videoViewComp.value?.offsetWidth;
  } else if (itemInfoModalShow.value === true) {
    wrapHeight.value = window.screen.height;
    wrapWidth.value = window.screen.width;
  }
}
function openModal() {
  if (!isDesktop) {
    itemInfoModalShow.value = true;
  }
}

onBeforeMount(() => {
  isDesktop = !useCheckMobile();
  if (!isDesktop) {
    unwatchShowStatus();
    wrapHeight.value = window.innerHeight;
    wrapWidth.value = window.innerWidth;
  }

  window.addEventListener('resize', windowResize);
});
onMounted(() => {
  if (props.isShow === true) {
    getWrapSize();
  }
});
onUnmounted(() => {
  if (streamNew.value !== undefined) {
    streamNew.value.getTracks().forEach(track => track.stop());
    streamNew.value = undefined;
  }
  if (recordCountdownInterval !== null) {
    clearInterval(recordCountdownInterval);
    recordCountdownInterval = null;
  }
  window.removeEventListener('resize', windowResize);
});
</script>

<style lang="scss">
.photo-icon {
  fill: var(--bs-accent);
  width: 50px;
  height: 50px;
  padding-bottom: 2px;
}

.photo {
  width: 250px;
  height: 250px;
}

.edit_photo {
  left: 21%;
  bottom: 5%;
}

@media (max-width: 767px) {
  .video-record {
    display: none;

    &-wrap, &-wrap__view {
      border-radius: 0 !important;
    }

    &-control {
      bottom: 25px;
      height: 60px;
      width: 60px;
      border: 4px solid var(--bs-white);

      &__indicator {
        display: block;
        height: 30px;
        width: 30px;
        background: var(--bs-accent);
        border-radius: 50%;
      }

      &_stop > &__indicator {
        background: var(--bs-primary);
        border-radius: 5px;
      }
    }

    &-flip {
      left: 75%;
      bottom: 32.5px;
      height: 45px;
      width: 45px;
    }
  }
}
</style>
